import React, {useState, useEffect} from 'react';
import './ShopHome.css';
import Header from '../Header/Header';
import Slider from '../Slider/Slider';
import Footer from '../Footer/Footer';
import FAQ from '../FAQ/FAQ';
import Terms from '../Terms/Terms';
import Privacy from '../Privacy/Privacy';
import { NumericStepper } from '@anatoliygatt/numeric-stepper';
import FacebookPixelPopup from '../FacebookPixelPopup/FacebookPixelPopup';

function ShopHome(props: any) {
  const amount: number = props.amount;
  const setAmount = props.setAmount;
  const setShopState = props.setShopState;
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(true);
  const price: number = props.price;
  const stock: number = props.stock;
  const [faqShown, setFaqShown] = useState<boolean>(false);
  const [termsShown, setTermsShown] = useState<boolean>(false);
  const [privacyShown, setPrivacyShown] = useState<boolean>(false);
  const [pixelShown, setPixelShown] = useState(true);
  const [cookieShown, setCookieShown] = useState<boolean>(false);

  useEffect(() => {
    if (amount == 0) {
      setAmount(1);
    }
  }, [amount])

  let buttonStateClass: string = ''; 
  if (!buttonEnabled) {
    buttonStateClass = 'disabled'
  }

  const action = () => {
    if (!buttonEnabled) {
      return;
    }
    setShopState(props.ShopState.EnterDetailPage);
  }

  const orderPrice: string = (amount * price).toFixed(2).replace('.', ',');
  const inStock: boolean = stock > 0;
  const priceLoading: boolean = orderPrice == '0,00';
  let buttonText: string;
  if (priceLoading) {
    buttonText = '...';
  } else {
    if (inStock) {
      buttonText = 'Bestellen';
    } else {
      buttonText = 'Uitverkocht';
    }
  }
  return (
    <div data-faqshown={faqShown}>
    <div data-termsshown={termsShown}></div>
    <div data-privacyshown={privacyShown}></div>
    <FacebookPixelPopup showPolicy={cookieShown} setShowPolicy={setCookieShown} showPopup={pixelShown} setShowPopup={setPixelShown} />
    <div className="ShopHome">
      <Header />
      {faqShown &&
        <FAQ setFaqShown={setFaqShown} />
      }
      {termsShown &&
        <Terms setTermsShown={setTermsShown} />
      }
      {privacyShown &&
        <Privacy setPrivacyShown={setPrivacyShown} />
      }
      <div className="leftSide">
        <h1><span className="line">Bestel hier je</span> <span className="line">Wall Sticks</span></h1>
        <p>
          Met Wall Sticks zijn de mogelijkheden eindeloos! Dankzij het unieke ontwerp, ben je zelf in staat om je creativiteit toe te passen en op een duurzame manier wanddecoratie te maken.
        </p>
        <p className="productContents">Een set Wall Sticks bestaat uit 110 sticks, 140 clips, bevestigingsmateriaal en een instructieboekje met inspiratie.</p>
        <div className="OrderBottom">
          {priceLoading
            ? <h2><div className="dot-shuttle"></div></h2>
            : <h2>€{orderPrice}</h2>
          }

      <NumericStepper
        size="sm"
        minimumValue={1}
        initialValue={1}
        onChange={(value) => {
          setAmount(value);
        }}
      />
          {inStock
            ? <button className={`orderButton ${buttonStateClass}`} onClick={action}>{buttonText}</button>
            : <button className='orderButton disabled'>{buttonText}</button>
          }
        </div>
      </div>
      <div className="rightSide">
        <Slider />
      </div>
    </div>
    <Footer setFaqShown={setFaqShown} setTermsShown={setTermsShown} setPrivacyShown={setPrivacyShown} setCookieShown={setCookieShown}/>
    </div>
  );
}

export default ShopHome;
