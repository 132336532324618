import React from 'react';
import './OrderInfo.css';
import confirmed from '../../assets/images/confirmed.svg'

function OrderInfo() {
  const params: any = new URLSearchParams(window.location.search)
  const orderid: string = params.get('orderid');
  const email: string = params.get('email');

  return (
      <div className="OrderInfo">
        <img className="confirmed" src={confirmed} />
        <h1>Bedankt voor je bestelling!</h1>
        <p className="topParagraph">Je bestelling met nummer <b>#{orderid}</b> is in goede orde ontvangen.<br/>Je krijgt binnen enkele minuten een mailtje met de bevestiging op <b>{email}</b>.</p>
        <p className='bottomParagraph'>We gaan aan de slag om jouw Wall Sticks zo snel mogelijk bij je te bezorgen!<br />Zodra ze zijn verstuurd ontvang je van ons nog een mailtje.</p>
        <div className='bottomInfo'>
          <div className='bottomInfoBlock'>
            <p>Mocht je nog vragen hebben over jouw bestelling of wil je iets wijzigen? <br />Dan kun je altijd mailen naar <b>info@wallsticks.nl</b></p>
          </div>
          <div className='bottomInfoBlock'>
            <p>Is er tijdens de bestelling iets mis gegaan of heb je geen bevestigingsmail gekregen? <br /> Mail dan naar <b>support@wallsticks.nl</b></p>
          </div>
        </div>
      </div>
  );
}

export default OrderInfo;