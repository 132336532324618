import React from 'react';
import './Thanks.css';
import Header from './components/Header/Header';
import OrderInfo from './components/OrderInfo/OrderInfo';

function Thanks() {
  return (
    <div className="ThanksPage">
      <Header />
      <OrderInfo />
    </div>
  );
}

export default Thanks;
