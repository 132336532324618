import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import './FacebookPixelPopup.css'

const FacebookPixelPopup = (props: any) => {
  const showPopup = props.showPopup;
  const setShowPopup = props.setShowPopup;

  const showPolicy = props.showPolicy;
  const setShowPolicy = props.setShowPolicy;

  const [utm, setUtm] = useState('');
  useEffect(() => {
    // Get the current URL search params
    const queryParams = new URLSearchParams(window.location.search);

    // Access specific query parameters
    const utm = queryParams.get('utm');
    if (utm && utm != '') {
      setUtm(utm);
    }
  }, []); // Empty dependency array ensures that this effect runs only

  const consent = () => {
    ReactPixel.init('1296915431181941');
    ReactPixel.grantConsent();
    ReactPixel.pageView(); // Track a page view event
    if (utm == 'imakin') {
      ReactPixel.trackCustom('Imakin');
    }
    closePopup();
  };

  const noConsent = () => {
    ReactPixel.revokeConsent();
    closePopup();
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowPolicy(false);
  };

  return (
    <>
      {showPopup && (
        <div className='pixelOverlay'>
          <div className="pixelPopup">
            <div className="pixelPopupContent">
              <h2>Toestemming voor het gebruik van Facebook Pixel</h2>
              <p>
                Wij maken gebruik van Facebook Pixel op onze website. Deze tool
                stelt ons in staat om de effectiviteit van onze advertenties te
                meten door het gedrag van bezoekers te analyseren.
              </p>
              <p>
                Door op 'Akkoord' te klikken, stemt u in met het gebruik van
                Facebook Pixel. U kunt uw voorkeuren op elk moment wijzigen in
                onze <a onClick={() => setShowPolicy(true)}>Cookie policy</a>.
              </p>
              <div className="btn-container">
                <button className="pixelPopupButton" onClick={consent}>
                  Akkoord
                </button>
                <button className="pixelPopupButton" onClick={noConsent}>
                  Weigeren
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showPolicy && (
        <div className='pixelOverlay'>
          <div className="pixelPopup">
            <div className="pixelPopupContent">
            <div className="closePixelPopup" onClick={() => setShowPolicy(false)}>X</div>
              <p>
                <h1>Cookiebeleid voor het gebruik van Facebook Pixel</h1>
                <br /><br />
                Dit cookiebeleid legt uit hoe onze website gebruikmaakt van cookies, waaronder de Facebook-pixel, en hoe je als gebruiker controle kunt uitoefenen over deze cookies. Door onze website te blijven gebruiken, ga je akkoord met het gebruik van cookies zoals beschreven in dit beleid.
                <br /><br />
                <strong>Wat zijn cookies?</strong>
                <br/>
                Cookies zijn kleine tekstbestanden die op je computer of mobiele apparaat worden opgeslagen wanneer je onze website bezoekt. Ze worden breed gebruikt om websites efficiënter te laten werken en om informatie te verstrekken aan website-eigenaren.
                <br /><br />
                <strong>Ons gebruik van cookies</strong>
                <br/>
                We maken gebruik van cookies, waaronder de Facebook-pixel, om informatie te verzamelen over hoe je onze website gebruikt. Deze informatie helpt ons om onze website te verbeteren en om gerichte advertenties aan te bieden.
                <br /><br />
                <strong>Wat is een Facebook-pixel?</strong>
                <br/>
                De Facebook-pixel is een analysehulpmiddel dat ons helpt de effectiviteit van onze advertenties te meten door de acties die mensen op de website ondernemen, te begrijpen. De gegevens zijn anoniem en stellen ons in staat advertenties te tonen aan mensen die mogelijk geïnteresseerd zijn in onze producten of diensten.
                <br /><br />
                <strong>Soorten cookies die we gebruiken:</strong>
                <br/>
                Strikt noodzakelijke cookies: Deze cookies zijn essentieel om je in staat te stellen door de website te navigeren en de functies ervan te gebruiken.
                Prestatiecookies: Deze cookies verzamelen informatie over hoe je onze website gebruikt, zoals welke pagina's je bezoekt en eventuele foutmeldingen die je ontvangt. Deze cookies worden gebruikt om de prestaties van de website te verbeteren.
                Advertentiecookies: We gebruiken advertentiecookies, waaronder de Facebook-pixel, om relevante advertenties te tonen op basis van je interesses en om de prestaties van onze advertentiecampagnes te meten.
                <br /><br />
                <strong>Cookiebeheer:</strong>
                <br/>
                Je kunt het gebruik van cookies beheren via de instellingen van je internetbrowser. Houd er rekening mee dat het uitschakelen van bepaalde cookies de functionaliteit van de website kan beïnvloeden.
                <br /><br />
                <strong>Wijzigingen in ons cookiebeleid:</strong>
                <br/>
                Dit cookiebeleid kan periodiek worden bijgewerkt om te voldoen aan wettelijke vereisten en om wijzigingen in ons cookiegebruik weer te geven. We raden je aan om regelmatig deze pagina te controleren op eventuele updates.
                <br /><br />
                <strong>Contact:</strong>
                <br/>
                Als je vragen hebt over ons cookiebeleid, neem dan contact met ons op.
              </p>
              <div className="btn-container">
                <button className="pixelPopupButton" onClick={consent}>
                  Akkoord
                </button>
                <button className="pixelPopupButton" onClick={noConsent}>
                  Niet akkoord
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FacebookPixelPopup;
