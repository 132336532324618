import React from 'react';
import './Header.css';

function Header() {
  return (
      <header>
        <a className="flexLeft" href='https://www.wallsticks.nl'>
            <div id="logo"></div>
            <h1>Wall Sticks</h1>
       </a>
       <div className="column flexCenter hamburgerMenu hidden">
        <div className="clickstick white"></div>
        <div className="clickstick white"></div>
        <div className="clickstick white"></div>
       </div>
      </header>
  );
}

export default Header;