import React from 'react';
import './DataOverview.css';

function DataOverview(props: any) {
    const setDataOverviewShown = props.setDataOverviewShown;
    const paymentAction = props.paymentAction;
    const buttonStateClass = props.buttonStateClass;
    const buttonText = props.buttonText;
    const name = props.name;
    const lastName = props.lastName;
    const email = props.email;
    const streetname = props.streetname;
    const houseNumber = props.houseNumber;
    const houseNumberExt = props.houseNumberExt;
    const city = props.city;
    const zipcode = props.zipcode;
    const amount = props.amount;
    const totalPrice = props.totalPrice;

    return (
    <div className="dataOverviewOverlay" onClick={() => setDataOverviewShown(false)}>
            <div className="dataOverviewPopup" onClick={(e) => e.stopPropagation()}>
                <div className="dataOverviewPopupContent">
                    <div className="closeDataOverviewPopup" onClick={() => setDataOverviewShown(false)}>X</div>
                    <h1>Kloppen deze gegevens?</h1>
                    <div className="dataOverViewSubsWrapper">
                    <div className="dataOverviewSub">
                        <h2>Adresgegevens:</h2>
                        <p>{name} {lastName}</p>
                        <p>{streetname} {houseNumber}{houseNumberExt}</p>
                        <p>{zipcode} {city}</p>
                        <p>{email}</p>      
                    </div> 
                    <div className="dataOverviewSub">
                        <h2>Jouw bestelling:</h2>
                        <h3>{amount}x Set Wall Sticks</h3>
                        <h2>€{totalPrice}</h2>
                    </div>
                </div>
                <button id="paymentButton" className={buttonStateClass} onClick={paymentAction}>{buttonText}</button>
            </div>
        </div>
    </div>
    )
}

export default DataOverview;