import React from 'react';
import './App.css';
import Shop from "./components/Shop/Shop";
import Thanks from "./components/Thanks/Thanks";

function App() {
  const params: any = new URLSearchParams(window.location.search)
  const orderid: string = params.get('orderid');
  if (!orderid || orderid == '') {
    return <Shop />
  } else {
    return <Thanks />
  }
}

export default App;
