import React from 'react';
import './Terms.css';

function Terms(props: any) {
  const setTermsShown = props.setTermsShown;

  return (
    <div className="termsOverlay" onClick={() => setTermsShown(false)}>
    <div className="termsPopup" onClick={(e) => e.stopPropagation()}>
      <div className="termsPopupContent">
      <div className="closeTermsPopup" onClick={() => setTermsShown(false)}>X</div>
      <h1>Algemene voorwaarden</h1>
        <p>&nbsp;</p>
        <p><strong>Inhoudsopgave:</strong></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 1 – Definities</span></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 2 – Identiteit van de ondernemer</span></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 3 – Toepasselijkheid</span></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 4 – Het aanbod</span></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 5 – De overeenkomst</span></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 6 – Herroepingsrecht</span></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 7 – Kosten in geval van herroeping</span></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 8 – Uitsluiting herroepingsrecht</span></p>
        <p><span style={{fontWeight: 400}}>Artikel &nbsp; 9 – De prijs</span></p>
        <p><span style={{fontWeight: 400}}>Artikel 10 – Conformiteit en garantie</span></p>
        <p><span style={{fontWeight: 400}}>Artikel 11 – Levering en uitvoering</span></p>
        <p><span style={{fontWeight: 400}}>Artikel 12 – Betaling</span></p>
        <p><span style={{fontWeight: 400}}>Artikel 13 – Klachtenregeling</span></p>
        <p><span style={{fontWeight: 400}}>Artikel 14 – Geschillen</span></p>
        <p><span style={{fontWeight: 400}}>Artikel 15 – Aanvullende of afwijkende bepalingen</span></p>
        <p><strong>&nbsp;</strong></p>
        <p><br /><br /><br /><br /><br /></p>
        <p><strong>Artikel 1 – Definities</strong></p>
        <p><span style={{fontWeight: 400}}>In deze voorwaarden wordt verstaan onder:</span></p>
        <ol>
          <li style={{fontWeight: 400}}><strong>Bedenktijd</strong><span style={{fontWeight: 400}}>: de termijn waarbinnen de consument gebruik kan maken van zijn herroepingsrecht;</span></li>
          <li style={{fontWeight: 400}}><strong>Consument</strong><span style={{fontWeight: 400}}>: de natuurlijke persoon die niet handelt in de uitoefening van beroep of bedrijf en een overeenkomst op afstand aangaat met de ondernemer;</span></li>
          <li style={{fontWeight: 400}}><strong>Dag</strong><span style={{fontWeight: 400}}>: kalenderdag;</span></li>
          <li style={{fontWeight: 400}}><strong>Duurtransactie</strong><span style={{fontWeight: 400}}>: een overeenkomst op afstand met betrekking tot een reeks van producten en/of diensten, waarvan de leverings- en/of afnameverplichting in de tijd is gespreid;</span></li>
          <li style={{fontWeight: 400}}><strong>Duurzame gegevensdrager</strong><span style={{fontWeight: 400}}>: elk middel dat de consument of ondernemer in staat stelt om informatie die aan hem persoonlijk is gericht, op te slaan op een manier die toekomstige raadpleging en ongewijzigde reproductie van de opgeslagen informatie mogelijk maakt.</span></li>
          <li style={{fontWeight: 400}}><strong>Herroepingsrecht</strong><span style={{fontWeight: 400}}>: de mogelijkheid voor de consument om binnen de bedenktijd af te zien van de overeenkomst op afstand;</span></li>
          <li style={{fontWeight: 400}}><strong>Modelformulier</strong><span style={{fontWeight: 400}}>: het modelformulier voor herroeping die de ondernemer ter beschikking stelt die een consument kan invullen wanneer hij gebruik wil maken van zijn herroepingsrecht.</span></li>
          <li style={{fontWeight: 400}}><strong>Ondernemer</strong><span style={{fontWeight: 400}}>: de natuurlijke of rechtspersoon die producten en/of diensten op afstand aan consumenten aanbiedt;</span></li>
          <li style={{fontWeight: 400}}><strong>Overeenkomst op afstand</strong><span style={{fontWeight: 400}}>: een overeenkomst waarbij in het kader van een door de ondernemer georganiseerd systeem voor verkoop op afstand van producten en/of diensten, tot en met het sluiten van de overeenkomst uitsluitend gebruik gemaakt wordt van één of meer technieken voor communicatie op afstand;</span></li>
          <li style={{fontWeight: 400}}><strong>Techniek voor communicatie op afstand</strong><span style={{fontWeight: 400}}>: middel dat kan worden gebruikt voor het sluiten van een overeenkomst, zonder dat consument en ondernemer gelijktijdig in dezelfde ruimte zijn samengekomen.</span></li>
          <li style={{fontWeight: 400}}><strong>Algemene Voorwaarden</strong><span style={{fontWeight: 400}}>: de onderhavige Algemene Voorwaarden van de ondernemer.</span></li>
        </ol>
        <p><strong>Artikel 2 – Identiteit van de ondernemer</strong></p>
        <p><span style={{fontWeight: 400}}>Wall Sticks&nbsp;</span></p>
        <p><span style={{fontWeight: 400}}>info@wallsticks.nl&nbsp;</span></p>
        <p><span style={{fontWeight: 400}}>KVK: 85408980&nbsp;</span></p>
        <p><span style={{fontWeight: 400}}>Btw-identificatienummer: NL863613743B01&nbsp;</span></p>
        <p>&nbsp;</p>
        <p><strong>Artikel 3 – Toepasselijkheid</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Deze algemene voorwaarden zijn van toepassing op elk aanbod van de ondernemer en op elke tot stand gekomen overeenkomst op afstand en bestellingen tussen ondernemer en consument.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Voordat de overeenkomst op afstand wordt gesloten, wordt de tekst van deze algemene voorwaarden aan de consument beschikbaar gesteld. Indien dit redelijkerwijs niet mogelijk is, zal voordat de overeenkomst op afstand wordt gesloten, worden aangegeven dat de algemene voorwaarden bij de ondernemer zijn in te zien en zij op verzoek van de consument zo spoedig mogelijk kosteloos worden toegezonden.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien de overeenkomst op afstand elektronisch wordt gesloten, kan in afwijking van het vorige lid en voordat de overeenkomst op afstand wordt gesloten, de tekst van deze algemene voorwaarden langs elektronische weg aan de consument ter beschikking worden gesteld op zodanige wijze dat deze door de consument op een eenvoudige manier kan worden opgeslagen op een duurzame gegevensdrager. Indien dit redelijkerwijs niet mogelijk is, zal voordat de overeenkomst op afstand wordt gesloten, worden aangegeven waar van de algemene voorwaarden langs elektronische weg kan worden kennisgenomen en dat zij op verzoek van de consument langs elektronische weg of op andere wijze kosteloos zullen worden toegezonden.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Voor het geval dat naast deze algemene voorwaarden tevens specifieke product- of dienstenvoorwaarden van toepassing zijn, is het tweede en derde lid van overeenkomstige toepassing en kan de consument zich in geval van tegenstrijdige algemene voorwaarden steeds beroepen op de toepasselijke bepaling die voor hem het meest gunstig is.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien één of meerdere bepalingen in deze algemene voorwaarden op enig moment geheel of gedeeltelijk nietig zijn of vernietigd worden, dan blijft de overeenkomst en deze voorwaarden voor het overige in stand en zal de betreffende bepaling in onderling overleg onverwijld vervangen worden door een bepaling dat de strekking van het oorspronkelijke zoveel mogelijk benaderd.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Situaties die niet in deze algemene voorwaarden zijn geregeld, dienen te worden beoordeeld ‘naar de geest’ van deze algemene voorwaarden.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Onduidelijkheden over de uitleg of inhoud van één of meerdere bepalingen van onze voorwaarden, dienen uitgelegd te worden ‘naar de geest’ van deze algemene voorwaarden.</span></li>
        </ol>
        <p><strong>&nbsp;</strong></p>
        <p><strong>Artikel 4 – Het aanbod</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien een aanbod een beperkte geldigheidsduur heeft of onder voorwaarden geschiedt, wordt dit nadrukkelijk in het aanbod vermeld.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Het aanbod is vrijblijvend. De ondernemer is gerechtigd het aanbod te wijzigen en aan te passen.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Het aanbod bevat een volledige en nauwkeurige omschrijving van de aangeboden producten en/of diensten. De beschrijving is voldoende gedetailleerd om een goede beoordeling van het aanbod door de consument mogelijk te maken. Als de ondernemer gebruik maakt van afbeeldingen zijn deze een waarheidsgetrouwe weergave van de aangeboden producten en/of diensten. Kennelijke vergissingen of kennelijke fouten in het aanbod binden de ondernemer niet.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Alle afbeeldingen, specificaties gegevens in het aanbod zijn indicatie en kunnen geen aanleiding zijn tot schadevergoeding of ontbinding van de overeenkomst.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Afbeeldingen bij producten zijn een waarheidsgetrouwe weergave van de aangeboden producten. </span><span style={{fontWeight: 400}}>Ondernemer kan niet garanderen dat de weergegeven kleuren exact overeenkomen met de echte kleuren van de producten.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Elk aanbod bevat zodanige informatie, dat voor de consument duidelijk is wat de rechten en verplichtingen zijn, die aan de aanvaarding van het aanbod zijn verbonden. Dit betreft in het bijzonder:</span></li>
          <ul>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de prijs inclusief belastingen;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de eventuele kosten van verzending;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de wijze waarop de overeenkomst tot stand zal komen en welke handelingen daarvoor nodig zijn;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>het al dan niet van toepassing zijn van het herroepingsrecht;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de wijze van betaling, aflevering en uitvoering van de overeenkomst;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de termijn voor aanvaarding van het aanbod, dan wel de termijn waarbinnen de ondernemer de prijs garandeert;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de hoogte van het tarief voor communicatie op afstand indien de kosten van het gebruik van de techniek voor communicatie op afstand worden berekend op een andere grondslag dan het reguliere basistarief voor het gebruikte communicatiemiddel;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>of de overeenkomst na de totstandkoming wordt gearchiveerd, en zo ja op welke wijze deze voor de consument te raadplegen is;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de manier waarop de consument, voor het sluiten van de overeenkomst, de door hem in het kader van de overeenkomst verstrekte gegevens kan controleren en indien gewenst herstellen;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de eventuele andere talen waarin, naast het Nederlands, de overeenkomst kan worden gesloten;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de gedragscodes waaraan de ondernemer zich heeft onderworpen en de wijze waarop de consument deze gedragscodes langs elektronische weg kan raadplegen; en</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de minimale duur van de overeenkomst op afstand in geval van een duurtransactie.</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Optioneel: beschikbare maten, kleuren, soort materialen.</span></li>
          </ul>
        </ol>
        <p><strong>Artikel 5 – De overeenkomst</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De overeenkomst komt, onder voorbehoud van het bepaalde in lid 4, tot stand op het moment van aanvaarding door de consument van het aanbod en het voldoen aan de daarbij gestelde voorwaarden.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien de consument het aanbod langs elektronische weg heeft aanvaard, bevestigt de ondernemer onverwijld langs elektronische weg de ontvangst van de aanvaarding van het aanbod. Zolang de ontvangst van deze aanvaarding niet door de ondernemer is bevestigd, kan de consument de overeenkomst ontbinden.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien de overeenkomst elektronisch tot stand komt, treft de ondernemer passende technische en organisatorische maatregelen ter beveiliging van de elektronische overdracht van data en zorgt hij voor een veilige web omgeving. Indien de consument elektronisch kan betalen, zal de ondernemer daartoe passende veiligheidsmaatregelen in acht nemen.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De ondernemer kan zich – binnen wettelijke kaders – op de hoogte stellen of de consument aan zijn betalingsverplichtingen kan voldoen, evenals van al die feiten en factoren die van belang zijn voor een verantwoord aangaan van de overeenkomst op afstand. Indien de ondernemer op grond van dit onderzoek goede gronden heeft om de overeenkomst niet aan te gaan, is hij gerechtigd gemotiveerd een bestelling of aanvraag te weigeren of aan de uitvoering bijzondere voorwaarden te verbinden.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De ondernemer zal bij het product of dienst aan de consument de volgende informatie, schriftelijk of op zodanige wijze dat deze door de consument op een toegankelijke manier kan worden opgeslagen op een duurzame gegevensdrager, meesturen:</span></li>
          <ol>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>het bezoekadres van de vestiging van de ondernemer waar de consument met klachten terecht kan;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de voorwaarden waaronder en de wijze waarop de consument van het herroepingsrecht gebruik kan maken, dan wel een duidelijke melding inzake het uitgesloten zijn van het herroepingsrecht;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de informatie over garanties en bestaande service na aankoop;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de in artikel 4 lid 3 van deze voorwaarden opgenomen gegevens, tenzij de ondernemer deze gegevens al aan de consument heeft verstrekt vóór de uitvoering van de overeenkomst;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de vereisten voor opzegging van de overeenkomst indien de overeenkomst een duur heeft van meer dan één jaar of van onbepaalde duur is.</span></li>
          </ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>In geval van een duurtransactie is de bepaling in het vorige lid slechts van toepassing op de eerste levering.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Iedere overeenkomst wordt aangegaan onder de opschortende voorwaarden van voldoende beschikbaarheid van de betreffende producten.</span></li>
        </ol>
        <p><strong>&nbsp;</strong></p>
        <p><strong>Artikel 6 – Herroepingsrecht</strong></p>
        <p><em><span style={{fontWeight: 400}}>Bij levering van producten:</span></em></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Bij de aankoop van producten heeft de consument de mogelijkheid de overeenkomst zonder opgave van redenen te ontbinden gedurende </span><span style={{fontWeight: 400}}>14 dagen</span><span style={{fontWeight: 400}}>. Deze bedenktermijn gaat in op de dag na ontvangst van het product door de consument of een vooraf door de consument aangewezen en aan de ondernemer bekendgemaakte vertegenwoordiger.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Tijdens de bedenktijd zal de consument zorgvuldig omgaan met het product en de verpakking. Hij zal het product slechts in die mate uitpakken of gebruiken voor zover dat nodig is om te kunnen beoordelen of hij het product wenst te behouden. Indien hij van zijn herroepingsrecht gebruik maakt, zal hij het product met alle geleverde toebehoren en – indien redelijkerwijze mogelijk – in de originele staat en verpakking aan de ondernemer retourneren, conform de door de ondernemer verstrekte redelijke en duidelijke instructies.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Wanneer de consument gebruik wenst te maken van zijn herroepingsrecht is hij verplicht dit binnen 14 dagen, na ontvangst van het product, kenbaar te maken aan de ondernemer. Het kenbaar maken dient de consument te doen middels het modelformulier. Nadat de consument kenbaar heeft gemaakt gebruik te willen maken van zijn herroepingsrecht dient de klant het product binnen 14 dagen retour te sturen. De consument dient te bewijzen dat de geleverde zaken tijdig zijn teruggestuurd, bijvoorbeeld door middel van een bewijs van verzending.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien de klant na afloop van de in lid 2 en 3 genoemde termijnen niet kenbaar heeft gemaakt gebruik te willen maken van zijn herroepingsrecht resp. het product niet aan de ondernemer heeft teruggezonden, is de koop een feit.</span></li>
        </ol>
        <p><em><span style={{fontWeight: 400}}>Bij levering van diensten:</span></em></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Bij levering van diensten heeft de consument de mogelijkheid de overeenkomst zonder opgave van redenen te ontbinden gedurende ten minste 14 dagen, ingaande op de dag van het aangaan van de overeenkomst.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Om gebruik te maken van zijn herroepingsrecht, zal de consument zich richten naar de door de ondernemer bij het aanbod en/of uiterlijk bij de levering ter zake verstrekte redelijke en duidelijke instructies.</span></li>
        </ol>
        <p><strong>Artikel 7 – Kosten in geval van herroeping</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien de consument gebruik maakt van zijn herroepingsrecht, komen ten hoogste de kosten van terugzending voor zijn rekening.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien de consument een bedrag betaald heeft, zal de ondernemer dit bedrag zo spoedig mogelijk, doch uiterlijk binnen 14 dagen na herroeping, terugbetalen. Hierbij is wel de voorwaarde dat het product reeds terugontvangen is door de webwinkelier of sluitend bewijs van complete terugzending overlegd kan worden. Terugbetaling zal geschieden via dezelfde betaalmethode die door de consument is gebruikt tenzij de consument nadrukkelijk toestemming geeft voor een andere betaalmethode.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Bij beschadiging van het product door onzorgvuldige omgang door de consument zelf is de consument aansprakelijk voor eventuele waardevermindering van het product.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De consument kan niet aansprakelijk worden gesteld voor waardevermindering van het product wanneer door de ondernemer niet alle wettelijk verplichte informatie over het herroepingsrecht is verstrekt, dit dient te gebeuren voor het sluiten van de koopovereenkomst.</span></li>
        </ol>
        <p><strong>Artikel 8 – Uitsluiting herroepingsrecht</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De ondernemer kan het herroepingsrecht van de consument uitsluiten voor producten zoals omschreven in lid 2 en 3. De uitsluiting van het herroepingsrecht geldt slechts indien de ondernemer dit duidelijk in het aanbod, althans tijdig voor het sluiten van de overeenkomst, heeft vermeld.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Uitsluiting van het herroepingsrecht is slechts mogelijk voor producten:</span></li>
          <ol>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>die door de ondernemer tot stand zijn gebracht overeenkomstig specificaties van de consument;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>die duidelijk persoonlijk van aard zijn;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>die door hun aard niet kunnen worden teruggezonden;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>die snel kunnen bederven of verouderen;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>waarvan de prijs gebonden is aan schommelingen op de financiële markt waarop de ondernemer geen invloed heeft;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>voor losse kranten en tijdschriften;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>voor audio- en video-opnamen en computersoftware waarvan de consument de verzegeling heeft verbroken.</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>voor hygiënische producten waarvan de consument de verzegeling heeft verbroken.</span></li>
          </ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Uitsluiting van het herroepingsrecht is slechts mogelijk voor diensten:</span></li>
          <ol>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>betreffende logies, vervoer, restaurantbedrijf of vrijetijdsbesteding te verrichten op een bepaalde datum of tijdens een bepaalde periode;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>waarvan de levering met uitdrukkelijke instemming van de consument is begonnen voordat de bedenktijd is verstreken;</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>betreffende weddenschappen en loterijen.</span></li>
          </ol>
        </ol>
        <p><strong>Artikel 9 – De prijs</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Gedurende de in het aanbod vermelde geldigheidsduur worden de prijzen van de aangeboden producten en/of diensten niet verhoogd, behoudens prijswijzigingen als gevolg van veranderingen in btw-tarieven.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>In afwijking van het vorige lid kan de ondernemer producten of diensten waarvan de prijzen gebonden zijn aan schommelingen op de financiële markt en waar de ondernemer geen invloed op heeft, met variabele prijzen aanbieden. Deze gebondenheid aan schommelingen en het feit dat eventueel vermelde prijzen richtprijzen zijn, worden bij het aanbod vermeld.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Prijsverhogingen binnen 3 maanden na de totstandkoming van de overeenkomst zijn alleen toegestaan indien zij het gevolg zijn van wettelijke regelingen of bepalingen.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Prijsverhogingen vanaf 3 maanden na de totstandkoming van de overeenkomst zijn alleen toegestaan indien de ondernemer dit bedongen heeft en:</span></li>
          <ol>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>deze het gevolg zijn van wettelijke regelingen of bepalingen; of</span></li>
            <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>de consument de bevoegdheid heeft de overeenkomst op te zeggen met ingang van de dag waarop de prijsverhoging ingaat.</span></li>
          </ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De in het aanbod van producten of diensten genoemde prijzen zijn inclusief btw.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Alle prijzen zijn onder voorbehoud van druk – en zetfouten. Voor de gevolgen van druk – en zetfouten wordt geen aansprakelijkheid aanvaard. Bij druk – en zetfouten is de ondernemer niet verplicht het product volgens de foutieve prijs te leveren.</span></li>
        </ol>
        <p><strong>Artikel 10 – Conformiteit en Garantie</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De ondernemer staat ervoor in dat de producten en/of diensten voldoen aan de overeenkomst, de in het aanbod vermelde specificaties, aan de redelijke eisen van deugdelijkheid en/of bruikbaarheid en de op de datum van de totstandkoming van de overeenkomst bestaande wettelijke bepalingen en/of overheidsvoorschriften. Indien overeengekomen staat de ondernemer er tevens voor in dat het product geschikt is voor ander dan normaal gebruik.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Een door de ondernemer, fabrikant of importeur verstrekte garantie doet niets af aan de wettelijke rechten en vorderingen die de consument op grond van de overeenkomst tegenover de ondernemer kan doen gelden.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Eventuele gebreken of verkeerd geleverde producten dienen binnen</span><span style={{fontWeight: 400}}> 2 maanden</span><span style={{fontWeight: 400}}> na levering aan de ondernemer schriftelijk te worden gemeld. Terugzending van de producten dient te geschieden in de originele verpakking en in nieuwstaat verkerend.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De garantietermijn van de ondernemer komt overeen met de fabrieksgarantietermijn. De ondernemer is echter te nimmer verantwoordelijk voor de uiteindelijke geschiktheid van de producten voor elke individuele toepassing door de consument, noch voor eventuele adviezen ten aanzien van het gebruik of de toepassing van de producten.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De garantie geldt niet indien:</span></li>
        </ol>
        <ul>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De consument de geleverde producten zelf heeft gerepareerd en/of bewerkt of door derden heeft laten repareren en/of bewerken;</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De geleverde producten aan abnormale omstandigheden zijn blootgesteld of anderszins onzorgvuldig worden behandeld of in strijd zijn met de aanwijzingen van de ondernemer en/of op de verpakking behandeld zijn;</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De ondeugdelijkheid geheel of gedeeltelijk het gevolg is van voorschriften die de overheid heeft gesteld of zal stellen ten aanzien van de aard of de kwaliteit van de toegepaste materialen.</span></li>
        </ul>
        <p><strong>Artikel 11 – Levering en uitvoering</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De ondernemer zal de grootst mogelijke zorgvuldigheid in acht nemen bij het in ontvangst nemen en bij de uitvoering van bestellingen van producten en bij de beoordeling van aanvragen tot verlening van diensten.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Als plaats van levering geldt het adres dat de consument aan het bedrijf kenbaar heeft gemaakt.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Met inachtneming van hetgeen hierover in lid 4 van dit artikel is vermeld, zal het bedrijf geaccepteerde bestellingen met bekwame spoed doch uiterlijk binnen </span><span style={{fontWeight: 400}}>30 dagen uitvoeren</span><span style={{fontWeight: 400}}>, tenzij consument akkoord is gegaan met een langere leveringstermijn. Indien de bezorging vertraging ondervindt, of indien een bestelling niet dan wel slechts gedeeltelijk kan worden uitgevoerd, ontvangt de consument hiervan uiterlijk 30 dagen nadat hij de bestelling geplaatst heeft bericht. De consument heeft in dat geval het recht om de overeenkomst zonder kosten te ontbinden. De consument heeft geen recht op een schadevergoeding.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Alle levertermijnen zijn indicatief. Aan eventuele genoemde termijnen kan de consument geen rechten ontlenen. Overschrijding van een termijn geeft de consument geen recht op schadevergoeding.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>In geval van ontbinding conform het lid 3 van dit artikel zal de ondernemer het bedrag dat de consument betaald heeft zo spoedig mogelijk, doch uiterlijk binnen 14 dagen na ontbinding, terugbetalen.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien levering van een besteld product onmogelijk blijkt te zijn, zal de ondernemer zich inspannen om een vervangend artikel beschikbaar te stellen. Uiterlijk bij de bezorging zal op duidelijke en begrijpelijke wijze worden gemeld dat een vervangend artikel wordt geleverd. Bij vervangende artikelen kan het herroepingsrecht niet worden uitgesloten. De kosten van een eventuele retourzending zijn voor rekening van de ondernemer.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Het risico van beschadiging en/of vermissing van producten berust bij de ondernemer tot het moment van bezorging aan de consument of een vooraf aangewezen en aan de ondernemer bekend gemaakte vertegenwoordiger, tenzij uitdrukkelijk anders is overeengekomen.</span></li>
        </ol>
        <p><strong>Artikel 12 – Betaling</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Voor zover niet anders is overeengekomen, dienen de door de consument verschuldigde bedragen te worden voldaan binnen</span><span style={{fontWeight: 400}}> 7 werkdagen</span><span style={{fontWeight: 400}}> na het ingaan van de bedenktermijn als bedoeld in artikel 6 lid 1. In geval van een overeenkomst tot het verlenen van een dienst, vangt deze termijn aan nadat de consument de bevestiging van de overeenkomst heeft ontvangen.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De consument heeft de plicht om onjuistheden in verstrekte of vermelde betaalgegevens onverwijld aan de ondernemer te melden.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>In geval van wanbetaling van de consument heeft de ondernemer behoudens wettelijke beperkingen, het recht om de vooraf aan de consument kenbaar gemaakte redelijke kosten in rekening te brengen.</span></li>
        </ol>
        <p><strong>Artikel 13 – Klachtenregeling</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>De ondernemer beschikt over een voldoende bekendgemaakte klachtenprocedure en behandelt de klacht overeenkomstig deze klachtenprocedure.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Klachten over de uitvoering van de overeenkomst moeten binnen 2 maanden volledig en duidelijk omschreven worden ingediend bij de ondernemer, nadat de consument de gebreken heeft geconstateerd.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Bij de ondernemer ingediende klachten worden binnen een termijn van 14 dagen gerekend vanaf de datum van ontvangst beantwoord. Als een klacht een voorzienbaar langere verwerkingstijd vraagt, wordt door de ondernemer binnen de termijn van 14 dagen geantwoord met een bericht van ontvangst en een indicatie wanneer de consument een meer uitvoerig antwoord kan verwachten.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien de klacht niet in onderling overleg kan worden opgelost ontstaat een geschil dat vatbaar is voor de geschillenregeling.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Bij klachten dient een consument zich allereerst te wenden tot de ondernemer. </span><span style={{fontWeight: 400}}>Indien de webwinkel is aangesloten bij Stichting WebwinkelKeur en bij klachten die niet in onderling overleg opgelost kunnen worden dient de consument zich te wenden tot Stichting WebwinkelKeur (</span><a href="http://www.webwinkelkeur.nl/"><span style={{fontWeight: 400}}>webwinkelkeur.nl</span></a><span style={{fontWeight: 400}}>), deze zal gratis bemiddelen. Controleer of deze webwinkel een lopend lidmaatschap heeft via </span><a href="https://www.webwinkelkeur.nl/ledenlijst/"><span style={{fontWeight: 400}}>https://www.webwinkelkeur.nl/ledenlijst/</span></a><span style={{fontWeight: 400}}>. Mocht er dan nog niet tot een oplossing gekomen worden, heeft de consument de mogelijkheid om zijn klacht te laten behandelen door de door Stichting WebwinkelKeur aangestelde onafhankelijke geschillencommissie, de uitspraak hiervan is bindend en zowel ondernemer als consument stemmen in met deze bindende uitspraak. Aan het voorleggen van een geschil aan deze geschillencommissie zijn kosten verbonden die door de consument betaald dienen te worden aan de betreffende commissie. Tevens is het mogelijk om klachten aan te melden via het Europees ODR platform (</span><a href="http://ec.europa.eu/odr"><span style={{fontWeight: 400}}>http://ec.europa.eu/odr</span></a><span style={{fontWeight: 400}}>).</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Een klacht schort de verplichtingen van de ondernemer niet op, tenzij de ondernemer schriftelijk anders aangeeft.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Indien een klacht gegrond wordt bevonden door de ondernemer, zal de ondernemer naar haar keuze of de geleverde producten kosteloos vervangen of repareren.</span></li>
        </ol>
        <p><strong>Artikel 14 – Geschillen</strong></p>
        <ol>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Op overeenkomsten tussen de ondernemer en de consument waarop deze algemene voorwaarden betrekking hebben, is uitsluitend Nederlands recht van toepassing. Ook indien de consument woonachtig is in het buitenland.</span></li>
          <li style={{fontWeight: 400}}><span style={{fontWeight: 400}}>Het Weens Koopverdrag is niet van toepassing.</span></li>
        </ol>
        <p><strong>Artikel 15 – Aanvullende of afwijkende bepalingen</strong></p>
        <p><span style={{fontWeight: 400}}>Aanvullende dan wel van deze algemene voorwaarden afwijkende bepalingen mogen niet ten nadele van de consument zijn en dienen schriftelijk te worden vastgelegd dan wel op zodanige wijze dat deze door de consument op een toegankelijke manier kunnen worden opgeslagen op een duurzame gegevensdrager.</span></p>
        <p><br /><br /></p>
      </div>
    </div>
  </div>
  );
}

export default Terms;