import React, {useState} from 'react';
import './Shop.css';
import ShopHome from "../../components/ShopHome/ShopHome";
import ShopData from "../../components/ShopData/ShopData";
import axios from 'axios';

enum ShopState{
  OrderPage,
  EnterDetailPage
}

function Shop() {
  const [shopState, setShopState] = useState<ShopState>(ShopState.OrderPage);
  const [amount, setAmount] = useState<number>(1);
  const [price, setPrice] = useState<number>(0);
  const [stock, setStock] = useState<number>(0);
  const [priceRetrieved, setPriceRetrieved] = useState<boolean>(false);

  if (!priceRetrieved) {
    setPriceRetrieved(true);
    axios({
      method: 'GET',
      url: 'https://kll4tx8ys5.execute-api.us-east-2.amazonaws.com/release'
    })
    .then(response => {
      setPrice(Number(response.data.body.price));
      setStock(Number(response.data.body.stock));
    });
  }

  switch (shopState) {
    case ShopState.OrderPage:
      
      return <ShopHome setShopState={setShopState} ShopState={ShopState} amount={amount} setAmount={setAmount} price={price} stock={stock}/>

    case ShopState.EnterDetailPage:
      return <ShopData setShopState={setShopState} ShopState={ShopState} amount={amount} price={price} stock={stock} />
  }
}

export default Shop;
