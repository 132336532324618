import React from 'react';
import './Privacy.css';

function Privacy(props: any) {
  const setPrivacyShown = props.setPrivacyShown;

  return (
    <div className="privacyOverlay" onClick={() => setPrivacyShown(false)}>
    <div className="privacyPopup" onClick={(e) => e.stopPropagation()}>
      <div className="privacyPopupContent">
      <div className="closePrivacyPopup" onClick={() => setPrivacyShown(false)}>X</div>
        <h1>Privacyverklaring</h1>
        <p>Wall Sticks is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
        <p>&nbsp;</p>
        <p><strong>Contactgegevens:</strong></p>
        <p>https://www.wallsticks.nl</p>
        <p>info@wallsticks.nl</p>
        <p>&nbsp;</p>
        <p><strong>Persoonsgegevens die wij verwerken</strong></p>
        <p>Wall Sticks verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.</p>
        <p>&nbsp;</p>
        <p>Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:</p>
        <p>- Voor- en achternaam</p>
        <p>- Adresgegevens</p>
        <p>- E-mailadres</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</strong></p>
        <p>Wall Sticks verwerkt jouw persoonsgegevens voor de volgende doelen:</p>
        <p>- Het afhandelen van jouw betaling</p>
        <p>- Je te kunnen bellen of e-mailen indien dit nodig is om onze dienstverlening uit te kunnen voeren</p>
        <p>- Om goederen en diensten bij je af te leveren</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Hoe lang we persoonsgegevens bewaren</strong></p>
        <p>Wall Sticks bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren een bewaartermijn van 2 jaar.</p>
        <p>&nbsp;</p>
        <p><strong>Delen van persoonsgegevens met derden</strong></p>
        <p>&nbsp;</p>
        <p>Wall Sticks verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Wall Sticks blijft verantwoordelijk voor deze verwerkingen. Jouw persoonsgegevens worden gedeeld met Mollie Payments om ervoor te zorgen dat de betaling zo veilig mogelijk word verwerkt en wordt vervolgens beveiligd opgeslagen bij AWS om jouw bestelling te kunnen verwerken.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Gegevens inzien, aanpassen of verwijderen </strong></p>
        <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Wall Sticks en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen.</p>
        <p>&nbsp;</p>
        <p>Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar info@wallsticks.nl.</p>
        <p>&nbsp;</p>
        <p>Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek .</p>
        <p>&nbsp;</p>
        <p>Wall Sticks wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><strong>Hoe wij persoonsgegevens beveiligen</strong></p>
        <p>Wall Sticks neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via info@wallsticks.nl</p>
      </div>
    </div>
  </div>
  );
}

export default Privacy;