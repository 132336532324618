import { format } from 'node:path/win32';
import React from 'react';
import './Footer.css';

function Footer(props: any) {
  const setFaqShown = props.setFaqShown;
  const setTermsShown = props.setTermsShown;
  const setPrivacyShown = props.setPrivacyShown;
const setCookieShown = props.setCookieShown

  return (
    <footer>
      <div id="logoWrapper">
        <div id="logo"></div>
        <h1>Wall Sticks</h1>
      </div>
      <div className='footerContent'>
        <div className='footerContentSubBlock left'>
          <p>Alle bestellingen worden <b>gratis verzonden</b> binnen Nederland.</p>
          <p>Bestellingen worden <b>binnen 3 werkdagen verzonden</b> via PostNL.</p>
          <p>Retourneren binnen <b>14 dagen.</b></p>
          <div className="faqLink" onClick={() => setFaqShown(true)}>Veelgestelde vragen</div>
          <div className="faqLink" onClick={() => setTermsShown(true)}>Algemene voorwaarden</div>
          <div className="faqLink" onClick={() => setPrivacyShown(true)}>Privacyverklaring</div>
          <div className="faqLink" onClick={() => setCookieShown(true)}>Cookie policy</div>
        </div>
        <div className='footerContentSubBlock right'>
          <p><strong>Contact:</strong></p>
          <p className="instagramTag"><span className="beforeInstagramTag"></span><a href="https://www.instagram.com/wallsticks/" target="_blank">WallSticks</a></p>
          <p>info@wallsticks.nl </p>
          <p>KVK: <b>85408980</b></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;