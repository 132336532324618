import React, {useState, useEffect} from 'react';
import './ShopData.css';
import axios from 'axios';
import InputMask from 'react-input-mask';
import Header from '../Header/Header';
import Terms from '../Terms/Terms';
import DataOverview from '../DataOverview/DataOverView';
import StockMessage from '../StockMessage/StockMessage';

function validateEmail(mail: string): boolean
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true);
  }
    return (false);
}

function ShopData(props: any) {
  const [discount, setDiscount] = useState(0);
  const amount: number = props.amount;
  const price: number = props.price;
  const stock: number = props.stock;
  const totalPrice: string = (amount * price - discount).toFixed(2).replace('.', ',');
  const setShopState = props.setShopState;
  const [name, setName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailCheck, setEmailCheck] = useState<string>('');
  const [streetname, setStreetname] = useState<string>('');
  const [housenumber, setHousenumber] = useState<string>('');
  const [housenumberExt, setHousenumberExt] = useState<string>('');
  const [zipcode, setZipcode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('Nederland');
  const [discountcode, setDiscountcode] = useState<string>('');
  const [agreed, setAgreed] = useState<boolean>(false);
  const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState<string>('Naar betalen (€' + totalPrice + ')');
  const [termsShown, setTermsShown] = useState<boolean>(false);
  const [dataOverviewShown, setDataOverviewShown] = useState<boolean>(false);
  const [stockMessageShown, setStockMessageShown] = useState<boolean>(false);


  useEffect(() => {
    if (
      (name != '')
      && (lastName != '')
      && (email != '')
      && (validateEmail(email))
      && (emailCheck == email)
      && (streetname != '')
      && (housenumber != '')
      && (zipcode != '')
      && (city != '')
      && (country != '')
      && (agreed)
    ) {
      setButtonEnabled(true);
    } else {
      setButtonEnabled(false);
    }

  if (
    (zipcode != '')
    && (housenumber != '')
    && (streetname == '')
    && (city == '')
  ) {
    axios({
      method: 'GET',
      url: `https://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:${zipcode}&fq=huisnummer~${housenumber}*`
    })
    .then(response => {
      setStreetname(response.data.response.docs[0].straatnaam);
      setCity(response.data.response.docs[0].woonplaatsnaam);
    });
  }
  if (discountcode == 'IMAKIN10') {
    setDiscount(6);
  } else {
    setDiscount(0);
  }
  }, [name, lastName, email, emailCheck, streetname, housenumber, zipcode, city, country, agreed, discountcode]);

  const beforeSetZipcode = (zipCode: string) => {
    const newZipcode = zipCode.toUpperCase();
    setZipcode(newZipcode);
  }

  const beforeSetHousenumber = (value: string) => {
    const numbers = /^[0-9]+$/;
    if(value.match(numbers) || value == '') {
       setHousenumber(value);
       return;
    } else {
      return;
    }
  }

  const beforeSetHousenumberExt = (value: string) => {
    const letters = /^[A-Za-z]+$/;
    if(value.match(letters) || value == '') {
       setHousenumberExt(value);
       return;
    } else {
      return;
    }
  }

  const beforeContinueOrder = () => {
    if (amount > stock) {
      setStockMessageShown(true);
      return;
    } else {
      setButtonText('Naar betalen (€' + totalPrice + ')');
      setDataOverviewShown(true);
      return;
    }
  }

  const action = () => {
    if (!buttonEnabled) {
      return;
    }
    const fullHouseNumber: string = housenumber + housenumberExt;

    const queryparams: string = `?amount=${amount}&name=${name}&lastName=${lastName}&email=${email}&streetname=${streetname}&housenumber=${fullHouseNumber}&zipcode=${zipcode}&city=${city}&country=${country}&discountcode=${discountcode}`;  
    const url: string = queryparams.replace(' ', '%20');
    setButtonEnabled(false);
    setButtonText('Een moment geduld...');
    axios({
      method: 'POST',
      url: 'https://hcdf2i6m1a.execute-api.us-east-2.amazonaws.com/payment' + url,
    })
    .then(response => window.location.href = response.data._links.checkout.href);
  }
  let buttonStateClass: string = ''; 
  if (!buttonEnabled) {
    buttonStateClass = 'disabled'
  }
  return (
      <div className="ShopData">
          <div data-stockmessageshown={stockMessageShown}></div>
          {stockMessageShown &&
            <StockMessage setStockMessageShown={setStockMessageShown} setDataOverviewShown={setDataOverviewShown} />
          }
          <div data-termsshown={termsShown}></div>
          {termsShown &&
            <Terms setTermsShown={setTermsShown} />
          }
          <div data-dataoverviewshown={dataOverviewShown}></div>
          {dataOverviewShown &&
            <DataOverview setDataOverviewShown={setDataOverviewShown} paymentAction={action} buttonStateClass={buttonStateClass} buttonText={buttonText} name={name} lastName={lastName} email={email} streetname={streetname} houseNumber={housenumber} houseNumberExt={housenumberExt} city={city} zipcode={zipcode} amount={amount} totalPrice={totalPrice} />
          }
          <Header />
          <button className="backButton" onClick={() => setShopState(props.ShopState.OrderPage)}></button>
          <div className='shopDataContentWrapper'>
            <div className='orderSummary'>
              <p>Jouw bestelling:</p>
              <h3>{amount}x Set Wall Sticks</h3>
              <h2>€{totalPrice}</h2>
              <div className="checkoutLine">
                <div className='inputField discount'>
                  <label>Heeft u een kortingscode?</label>
                  <input type="text" placeholder="Kortingscode" onChange={(event) => setDiscountcode(event.target.value)} value={discountcode}/>
                  <p>De kortingscode wordt toegepast zodra u op "Verder" klikt</p>
                </div>
              </div>
            </div>

            <div className='shopDataContent'>
              <div className="checkoutLine">
                <div className='inputField'>
                  <label>Voornaam</label>
                  <input type="text" placeholder="Voornaam" onChange={(event) => setName(event.target.value)} value={name}/>
                </div>
                <div className='inputField'>
                  <label>Achternaam</label>
                  <input type="text" placeholder="Achternaam" onChange={(event) => setLastName(event.target.value)} value={lastName}/>
                </div>
              </div>
              <div className="checkoutLine">
                <div className='inputField'>
                  <label>E-mailadres</label>
                  <input type="text" placeholder="E-mail adres" onChange={(event) => setEmail(event.target.value)} value={email}/>
                </div>
              </div>
              <div className="checkoutLine">
                <div className='inputField'>
                  <label>Herhaal e-mailadres ter controle </label>
                  <input type="text" placeholder="E-mail adres" onChange={(event) => setEmailCheck(event.target.value)} value={emailCheck}/>
                </div>
              </div>
              <div className="checkoutLine">
                <div className='inputField zipCode'>
                  <label>Postcode</label>
                  <InputMask mask="9999aa" onChange={(event) => beforeSetZipcode(event.target.value)} value={zipcode}>
                    {() => <input type="text" placeholder="0000AA"/> }
                  </InputMask>
                </div>
                <div className='inputField houseNumber'>
                  <label>Huisnummer</label>
                  <input type="text" placeholder="0" maxLength={5} onChange={(event) => beforeSetHousenumber(String(event.target.value))} value={housenumber}/>
                </div>
                <div className='inputField houseNumberExt'>
                  <label>Toevoeging</label>
                  <input type="text" maxLength={3} onChange={(event) => beforeSetHousenumberExt(String(event.target.value))} value={housenumberExt}/>
                </div>
              </div>
              <div className="checkoutLine">
              <div className='inputField'>
                  <label>Straatnaam</label>
                  <input type="text" placeholder="Straat" onChange={(event) => setStreetname(event.target.value)} value={streetname}/>
                </div>
              </div>
              <div className="checkoutLine">
                <div className='inputField'>
                  <label>Woonplaats</label>
                  <input type="text" placeholder="Woonplaats" onChange={(event) => setCity(event.target.value)} value={city}/>
                </div>
              </div>
              <div className="checkoutLine hidden">
                <div className='inputField'>
                  <label>Land</label>
                  <select>
                    <option selected value="Nederland">Nederland</option>
                    <option value="Belgie">België</option>
                  </select>
                </div>
              </div>
              <button id="continueOrderButton" className={buttonStateClass} onClick={() => beforeContinueOrder()}>Verder</button>
              <div className='checkBoxAgreedWrapper'>
                <input id="checkBoxAgreed" type="checkbox" onChange={(event) => { setAgreed(event.target.checked)} }></input>
                <label htmlFor="checkBoxAgreed" className="agreement">Ik ga akkoord met de <span className="link" onClick={() => setTermsShown(true)}>algemene voorwaarden</span>.</label>
              </div>
            </div>
          </div>
      </div>
    );
}

export default ShopData;
