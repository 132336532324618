import { format } from 'node:path/win32';
import React from 'react';
import './FAQ.css';

function FAQ(props: any) {
  const setFaqShown = props.setFaqShown;

  return (
    <div className="faqOverlay" onClick={() => setFaqShown(false)}>
    <div className="faqPopup" onClick={(e) => e.stopPropagation()}>
      <div className="faqPopupContent">
      <div className="closeFaqPopup" onClick={() => setFaqShown(false)}>X</div>
      <h1>Veelgestelde vragen</h1>
      <h3 className="faqQuestion">Hoe wordt mijn bestelling verzonden?</h3>
      <p className="faqAnswer">De bestelling wordt via PostNL binnen 3 werkdagen verzonden. PostNL bezorgt het pakketje daarna meestal binnen 2 werkdagen. Je ontvangt van ons een verzendbevestiging inclusief track & trace code wanneer je bestelling is verzonden.</p>
      
      <h3 className="faqQuestion">Kan ik mijn bestelling retourneren?</h3>
      <p className="faqAnswer">Alle bestellingen kunnen ongeacht de reden binnen de wettelijke bedenktijd van 14 dagen worden geretourneerd. Hiervoor kun je mailen naar info@wallsticks.nl. Dan ontvang je van ons de vervolgstappen van het retourneerproces. Eventuele verzendkosten voor het retourneren zijn voor de koper. <br /> Ook wanneer de ontvangen bestelling defect is of iets ontbreekt, kun je mailen naar info@wallsticks.nl. Dan lossen wij het zo snel mogelijk op.</p>
      
      <h3 className="faqQuestion">Hoe kan ik betalen?</h3>
      <p className="faqAnswer">Het is momenteel alleen mogelijk om te betalen via iDEAL. De betalingen verlopen veilig via Mollie. Zodra de betaling is gedaan ontvang je van ons een mailtje met de bevestiging van de bestelling.</p>

      <h3 className="faqQuestion">Wat moet ik doen als ik niet tevreden ben met mijn bestelling?</h3>
      <p className="faqAnswer">Ook voor alle klachten kun je ons mailen op info@wallsticks.nl. Wij doen ons uiterste best om alle klachten te verhelpen en tot een oplossing te komen.</p>
    
      <h3 className="faqQuestion">Waar kan ik voorbeelden vinden van modellen die ik met Wall Sticks kan maken?</h3>
      <p className="faqAnswer">In de doos die je ontvangt zit een boekje met een aantal voorbeelden. Ook zullen wij <a href="https://www.wallsticks.nl">wallsticks.nl</a> blijven updaten met nieuwe inspiratie. Verder kun je op Instagram zoeken naar #WallSticks om je eigen creaties te delen en om creaties van anderen te bekijken. <br /> Wanneer je op een platform als Pinterest zoekt op "Polygon line art" kun je ook veel ideeën vinden die met Wall Sticks te realiseren zijn.</p>
    
      <h3 className="faqQuestion">Staat jouw vraag hier niet tussen?</h3>
      <p className="faqAnswer">Mail ons op info@wallsticks.nl. Dan beantwoorden wij jouw vraag zo snel mogelijk!</p>
      </div>
    </div>
  </div>
  );
}

export default FAQ;