import React from 'react';
import './StockMessage.css';

function StockMessage(props: any) {
    const setStockMessageShown = props.setStockMessageShown;
    const setDataOverviewShown = props.setDataOverviewShown;

    const agreed = () => {
        setStockMessageShown(false);
        setDataOverviewShown(true);
        return;
    }

    return (
        <div className="stockMessageOverlay" onClick={() => setStockMessageShown(false)}>
        <div className="stockMessagePopup" onClick={(e) => e.stopPropagation()}>
            <div className="stockMessagePopupContent">
                <div className="closeStockMessagePopup" onClick={() => setStockMessageShown(false)}>X</div>
                <h1>Let op:</h1>
                <h2>Vanwege drukte en de grootte van jouw bestelling zal het langer dan normaal duren om jouw bestelling te verzenden.</h2>
                <button onClick={() => agreed()}>Akkoord</button>
            </div>
        </div>
    </div>
    );
}

export default StockMessage;